import { Box, Center } from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { Border } from '@ours/utils';
import { memo } from 'react';
import type { FC } from 'react';

import { CorrectIcon } from './CorrectIcon';
import { IncorrectIcon } from './IncorrectIcon';
import { dotSize } from './constants';

interface DraggableProps {
  color: string;
  isAnswer: boolean;
  isCorrect?: boolean;
  n: number;
}
export const Draggable: FC<DraggableProps> = memo(({ color, isAnswer, isCorrect, n }) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    data: { color, n },
    id: n,
  });

  return (
    <Box
      _hover={{ bg: `brand.${color}Hover` }}
      as="button"
      bg={`brand.${color}`}
      border={Border}
      cursor={isDragging ? 'grabbing' : 'grab'}
      h={dotSize}
      id={`${n}-end`}
      ref={setNodeRef}
      rounded="full"
      transform={transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined}
      w={dotSize}
      zIndex={10}
      {...listeners}
      {...attributes}
    >
      <Center fontWeight="600" h="full">
        {isAnswer ? isCorrect ? <CorrectIcon /> : <IncorrectIcon /> : isDragging ? null : n}
      </Center>
    </Box>
  );
});
