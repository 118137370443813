/* eslint-disable max-lines-per-function */
import { Box, Center, Flex, Grid } from '@chakra-ui/react';
import { useDndContext } from '@dnd-kit/core';
import { Border, Colors } from '@ours/utils';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { memo } from 'react';
import { TbRefresh } from 'react-icons/tb';
import { Textfit } from 'react-textfit';
import Xarrow from 'react-xarrows';

import { Draggable } from './Draggable';
import { Droppable } from './Droppable';
import { InitialDragZone } from './InitialDragZone';
import type { QuizResponse } from './constants';

const RainbowArray = ['blue', 'orange', 'pink', 'yellow', 'red', 'blue', 'orange', 'pink'];

const variants = {
  rotate: {
    rotate: [0, 360, 0],
    transition: { duration: 2 },
  },
  stop: {},
};
const h = { base: '50px', md: '60px' };
const w = { base: '100%', md: '200px' };

export const QuizContent: FC<{
  answers: string[];
  correctResponses: QuizResponse;
  isResetting: boolean;
  onReset: () => void;
  questions: string[];
  responses: QuizResponse;
}> = memo(({ answers, correctResponses, isResetting, onReset, questions, responses }) => {
  const { active } = useDndContext();
  // @ts-expect-error idx
  const activeColor = Colors.brand[active?.data.current.color];
  const templateAreas = questions.map((_, idx) => `"question-${idx} . answer-${idx}"`).join('');

  return (
    <Box>
      <Grid
        rowGap={200}
        templateAreas={'". . reset"' + templateAreas}
        templateColumns={`3fr minmax(30px, 100px) 3fr`}
      >
        <Flex gridArea="reset" justifyContent="flex-end" mb="-10px" w={w}>
          <motion.div
            animate={isResetting ? 'rotate' : 'stop'}
            style={{ height: '20px' }}
            variants={variants}
          >
            <Box _hover={{ svg: { stroke: 'ui.gray.07' } }} as="button" onClick={onReset}>
              <TbRefresh />
            </Box>
          </motion.div>
        </Flex>
        {questions.map((question, idx) => {
          const color = RainbowArray[idx] || 'blue';
          const n = idx + 1;
          const qHasResponse = Object.entries(responses).find(([, q]) => q === n);

          return (
            <Flex
              gridArea={`question-${idx}`}
              justifyContent="flex-end"
              key={question}
              pos="relative"
              w="full"
            >
              <Center bg="white" border={Border} borderRadius="700" h={h} px="300" py="10px" w={w}>
                <Textfit mode="multi" style={{ lineHeight: '1.2', maxHeight: '100%' }}>
                  {question}
                </Textfit>
              </Center>

              {qHasResponse ? null : (
                <Box pos="absolute" right="-25px" top="50%" transform="translateY(-50%)" zIndex={5}>
                  <Draggable color={color} isAnswer={false} n={n} />
                </Box>
              )}

              <Box pos="absolute" right="-25px" top="50%" transform="translateY(-50%)">
                <InitialDragZone color={color} n={n} />
              </Box>
            </Flex>
          );
        })}

        {answers.map((ans, idx) => {
          const n = idx + 1;
          const ansResponse = responses[n];
          const color = ansResponse ? RainbowArray[ansResponse - 1] || 'blue' : 'blue';

          return (
            <Flex gridArea={`answer-${idx}`} key={ans} pos="relative" w="full">
              {ansResponse ? (
                <Box left="-25px" pos="absolute" top="50%" transform="translateY(-50%)" zIndex={10}>
                  <Draggable
                    color={color}
                    isAnswer
                    isCorrect={correctResponses[n] === ansResponse}
                    n={ansResponse!}
                  />
                </Box>
              ) : null}

              <Box left="-25px" pos="absolute" top="50%" transform="translateY(-50%)">
                <Droppable color={activeColor} n={n} />
              </Box>

              <Center bg="white" border={Border} borderRadius="700" h={h} px="300" py="100" w={w}>
                <Textfit mode="multi" style={{ lineHeight: '1.2', maxHeight: '100%' }}>
                  {ans}
                </Textfit>
              </Center>
            </Flex>
          );
        })}

        {active ? (
          <Xarrow
            color={activeColor}
            divContainerStyle={{ position: 'inherit' }}
            end={`${active?.data.current?.n}-end`}
            endAnchor="left"
            showHead={false}
            showTail={false}
            start={`${active?.data.current?.n}-start`}
            startAnchor="right"
            strokeWidth={5}
          />
        ) : null}

        {Object.entries(responses)
          .filter(([, q]) => !active || active.id !== q)
          .map(([answerN, questionN]) => (
            <Xarrow
              // @ts-expect-error idx
              color={Colors.brand[RainbowArray[questionN - 1]]}
              divContainerStyle={{ position: 'inherit' }}
              end={`${answerN}-drop`}
              endAnchor="left"
              key={questionN}
              showHead={false}
              showTail={false}
              start={`${questionN}-start`}
              startAnchor="right"
              strokeWidth={5}
            />
          ))}
      </Grid>
    </Box>
  );
});
