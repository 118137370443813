import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { omitBy, shuffle, wait } from '@ours/utils';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Xwrapper, useXarrow } from 'react-xarrows';

import { launchConfetti } from '../../components/Confetti/launchConfetti';
import { useAnalyticsContext } from '../../hooks/analytics/analyticsContext';
import { useAsyncEvent } from '../../hooks/useAsyncEvent';
import { useEvent } from '../../hooks/useEvent';

import { QuizContent } from './lib/QuizContent';
import type { QuizResponse } from './lib/constants';

export interface QuizMatchingProps {
  pairs: [q: string, a: string][];
}

export const QuizMatching: FC<QuizMatchingProps> = memo(({ pairs }) => {
  const questions = useMemo(() => pairs.map(([p]) => p), [pairs]);
  const answers = useMemo(() => shuffle(pairs.map(([, p]) => p)), [pairs]);
  const { trackEvent } = useAnalyticsContext();
  const correctResponses = useMemo(
    () =>
      answers.reduce(
        (acc, cur, idx) => ({
          ...acc,
          [idx + 1]: 1 + questions.findIndex((q) => q === pairs.find(([, a]) => a === cur)?.[0]),
        }),
        {} as QuizResponse
      ),
    [pairs, questions, answers]
  );

  const [responses, setResponses] = useState<QuizResponse>({});
  const updateXarrow = useXarrow();

  const onDragEnd = useEvent((event: DragEndEvent) => {
    updateXarrow();
    const { active, over } = event;

    if (!over) {
      setResponses((x) => {
        const existing = omitBy(x, (val) => val === active.id) as QuizResponse;
        return { ...existing };
      });
      return;
    }

    setResponses((x) => {
      const existing = omitBy(x, (val) => val === active.id) as QuizResponse;
      return { ...existing, [over.id]: active.id };
    });

    // @ts-expect-error idx
    if (correctResponses[over.id] === active.id) {
      launchConfetti({
        origin: {
          x: over.rect.left / window.innerWidth,
          y: (over?.rect.top + 65) / window.innerHeight,
        },
        size: 'small',
      });
    }
  });

  useEffect(() => {
    if (
      pairs.length > 0 &&
      Object.entries(correctResponses).every(([a, q]) => q === responses[+a])
    ) {
      launchConfetti({ delay: 400, size: 'large' });
      trackEvent({ type: 'QUIZ_COMPLETE' });
    }
  }, [pairs, correctResponses, responses, trackEvent]);

  const [onReset, isResetting] = useAsyncEvent(async (): Promise<void> => {
    setResponses({});
    await wait(1500);
  });

  if (pairs.length === 0) {
    return null;
  }

  return (
    <Xwrapper>
      <DndContext onDragCancel={updateXarrow} onDragEnd={onDragEnd} onDragMove={updateXarrow}>
        <QuizContent
          answers={answers}
          correctResponses={correctResponses}
          isResetting={isResetting}
          onReset={onReset}
          questions={questions}
          responses={responses}
        />
      </DndContext>
    </Xwrapper>
  );
});
