import type { Position } from '@ours/types';
import { isBetween, wait } from '@ours/utils';

import { confettiColors } from './constants';

interface ConfettiOptions {
  delay?: number;
  origin?: Position;
  size?: 'small' | 'medium' | 'large';
}
const lookupParticle: Record<Required<ConfettiOptions>['size'], number> = {
  large: 200,
  medium: 100,
  small: 40,
};
const lookupVelocity: Record<Required<ConfettiOptions>['size'], number> = {
  large: 40,
  medium: 33,
  small: 24,
};

export const launchConfetti = async (options?: ConfettiOptions) => {
  try {
    const { default: shootConfetti } = await import('canvas-confetti');
    if (options?.delay) {
      await wait(options.delay);
    }

    const origin = options?.origin
      ? isBetween(options?.origin.x, { lower: 0, upper: 1 }) &&
        isBetween(options?.origin.y, { lower: 0, upper: 1 })
        ? options?.origin
        : undefined
      : undefined;

    await shootConfetti({
      colors: confettiColors,
      origin,
      particleCount: lookupParticle[options?.size || 'large'],
      spread: 360,
      startVelocity: lookupVelocity[options?.size || 'large'],
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error shooting confetti');
    // eslint-disable-next-line no-console
    console.error(err);
  }
};
