import { Box, Center } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { dotSize } from './constants';

interface InitialDragZoneProps {
  color: string;
  n: number;
}
export const InitialDragZone: FC<InitialDragZoneProps> = memo(({ color, n }) => {
  return (
    <Box
      bg={'brand.' + color}
      border={Border}
      h={dotSize}
      id={`${n}-start`}
      pointerEvents="none"
      rounded="full"
      w={dotSize}
    >
      <Center fontWeight="600" h="full">
        {n}
      </Center>
    </Box>
  );
});
