import { Box, Center } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import { Border } from '@ours/utils';
import { memo } from 'react';
import type { FC } from 'react';

import { dotSize } from './constants';

interface DroppableProps {
  color: string | undefined;
  n: number;
}
export const Droppable: FC<DroppableProps> = memo(({ color, n }) => {
  const { isOver, setNodeRef } = useDroppable({ id: n });

  return (
    <Box
      bg="white"
      border={Border}
      borderColor={isOver ? `${color}!important` : 'black'}
      h={dotSize}
      id={`${n}-drop`}
      ref={setNodeRef}
      rounded="full"
      w={dotSize}
    >
      <Center fontWeight="600" h="full"></Center>
    </Box>
  );
});
