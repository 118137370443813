import type { FC } from 'react';
import { memo } from 'react';

export interface CorrectIconProps {}

export const CorrectIcon: FC<CorrectIconProps> = memo(() => {
  return (
    <svg
      fill="none"
      height="29"
      viewBox="0 0 154 126"
      width="29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.2128 72.1236L58.9365 72.8175L59.6285 72.092L126.613 1.87145L152.093 27.3837L59.16 124.01L6.77277 72.6522C6.77156 72.651 6.77035 72.6498 6.76914 72.6485C3.37819 69.2511 1.45349 64.6392 1.41986 59.8272C1.38622 55.0137 3.24747 50.398 6.58962 46.9938C9.93231 43.5907 14.483 41.6763 19.2412 41.6699C23.9998 41.6635 28.58 43.5662 31.9729 46.964L31.9806 46.9717L31.9885 46.9793L58.2128 72.1236Z"
        fill="black"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});
