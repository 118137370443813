import type { FC } from 'react';
import { memo } from 'react';

export interface IncorrectIconProps {}

export const IncorrectIcon: FC<IncorrectIconProps> = memo(() => {
  return (
    <svg fill="none" height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.60538 24.2688C4.60118 23.2646 4.60118 21.6365 5.60538 20.6323L22.5759 3.66173L26.2125 7.29828L9.24193 24.2688C8.23773 25.273 6.60959 25.273 5.60538 24.2688Z"
        fill="black"
      />
      <path
        d="M5.60587 5.48007C4.60167 6.48427 4.60167 8.11241 5.60587 9.11661L22.5764 26.0872L26.213 22.4506L9.24242 5.48007C8.23822 4.47586 6.61008 4.47586 5.60587 5.48007Z"
        fill="black"
      />
    </svg>
  );
});
